document.addEventListener("DOMContentLoaded", function () {
    const fadeText = document.querySelectorAll(".fade-in-text");
    if (fadeText.length > 0) {
        fadeText.forEach((node) => {
            node.classList.add("fade__show");
        })
    }

    setMediaQueryForMobile()
});

function setMediaQueryForMobile() {
    if (window.innerWidth < 887) {
        const selectors = ['.my-title', '.container-row', '.opinion', '.my-name', '.container__canvas__row']
        for (const selector of selectors) {
            const el = document.querySelector(selector)
            if (el) {
                el.classList.add(selector.split('.').join('') + '__mobile')
            }
        }
    }
}
